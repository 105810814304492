import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import useHttp from 'common/hooks/useHttp';
import { RefreshTokenName } from 'common/utils/constants';
import { isEmpty } from 'common/utils/methods';
import { useAuthContext } from './auth-context';
import { useRouter } from 'next/router';
interface IContext {
  islaoding: boolean;
  toggleLoading: (value: boolean) => void;
  data: Profile | null;
  setData: (value: Profile) => void;
  resetData: () => void;
  refetchProfile: () => void;
}
const Context = createContext<IContext | null>(null);

export function useProfileContext() {
  return useContext(Context);
}

const Provider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<Profile | null>(null);
  const [islaoding, setIsLoading] = useState(false);
  const { sendRequest: fetchProfile } = useHttp();
  const router = useRouter();
  const authCtx = useAuthContext();
  const refreshToken = authCtx?.tokens[RefreshTokenName];

  useEffect(() => {
    if (!data && refreshToken) {
      fetchProfile({ url: '/profile' }, endFetch);
    }
  }, [refreshToken]);

  const refetchProfile = () => {
    fetchProfile({ url: '/profile' }, endFetch);
  };

  useEffect(() => {
    if (data) {
      if (isEmpty(data.name) || isEmpty(data.mobile)) router.push('/welcome');
    }
  }, [data]);

  const endFetch = (res: Responsive) => {
    setData(res.data);
  };

  const toggleLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const resetData = () => {
    setData(null);
  };

  return (
    <Context.Provider
      value={{
        islaoding,
        toggleLoading,
        data,
        setData,
        resetData,
        refetchProfile,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default Provider;
