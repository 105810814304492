import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface InitialState {
  data: Record<string, string[]>;
}

const initialState: InitialState = {
  data: {},
};

export const slice = createSlice({
  name: 'report-gallery',
  initialState,
  reducers: {
    setReportGalleryData: (
      state,
      action: PayloadAction<Record<string, string[]>>
    ) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { setReportGalleryData } = slice.actions;
export const selectReportGallery = (state: RootState) => state.reportGallery;
export default slice.reducer;
