import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../index';

interface InitialState {
  data: InspectionUtility[] | null;
  utilityLocations: { id: string; label: string }[] ;
}

const initialState: InitialState = {
  data: null,
  utilityLocations: [],
};

export const slice = createSlice({
  name: 'inspection/utilities',
  initialState,
  reducers: {
    setUtilitiesData: (state, action: PayloadAction<InspectionUtility[]>) => {
      state.data = action.payload;
    },
    setUtilityLocations: (
      state,
      action: PayloadAction<{ id: string; label: string }[]>
    ) => {
      state.utilityLocations = action.payload;
    },
  },
});

export const { setUtilitiesData, setUtilityLocations } = slice.actions;
export const selectUtilitiesInspection = (state: RootState) =>
  state.inspectionUtilities;
export default slice.reducer;
