import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface InitialState {
  data: Inspection | null;
  initialData: Inspection | null;
  steps: InspectionSteps | null;
}

const initialState: InitialState = {
  initialData: null,
  data: null,
  steps: null,
};

export const slice = createSlice({
  name: 'inspection',
  initialState,
  reducers: {
    setInspectionData: (state, action: PayloadAction<Inspection>) => {
      state.data = action.payload;
      state.initialData = action.payload;
    },

    onChangeInspectionData: (state, action: PayloadAction<Inspection>) => {
      state.data = action.payload;
    },

    setInspectionSteps: (state, action: PayloadAction<InspectionSteps>) => {
      state.steps = action.payload;
    },
  },
});

export const { setInspectionData, onChangeInspectionData, setInspectionSteps } =
  slice.actions;
export const selectInspection = (state: RootState) => state.inspection;
export default slice.reducer;
