import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react';
import { useBaseData } from 'common/hooks/useApi';
import { useRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from 'common/store/hooks';
import { handleData, selectAddProperty } from 'common/store/addProperty/slice';
import { isEmpty } from 'common/utils/methods';
interface IContext {
  next: (propertyId?: number) => void;
  back: (propertyId?: number) => void;
  onChangePosition: (value: Position) => void;
  zoomMap: number;
  handleZoomMap: (value: number) => void;
  propertyTypesObjectValue: Record<number, string>;
  propertyFeaturesObjectValue: Record<number, string>;
  propertyLettingTypeObjectValue: Record<number, string>;
  isConfirmBtn: boolean;
  setIsConfirmBtn: Dispatch<SetStateAction<boolean>>;
  lastStep: number;
  handleRoute: (step: number) => void;
}
const Context = createContext<IContext | null>(null);

export function usePropertyContext() {
  return useContext(Context);
}

export const convertPropertyData = (data: Property) => {
  const {
    files,
    features,
    cover,
    deposit,
    monthlyRent,
    type,
    address,
    agentBankAccount,
  } = data;
  return {
    ...data,
    files: files
      ? files.map((item: any) => {
          return {
            type: item.type,
            fileId: item.file.id,
            fileType: item.file.type,
            value: item.file.downloadUrl,
          };
        })
      : [],
    address: {
      ...address,
      cityId: Number(address?.city?.id),
      stateId: Number(address?.state?.id),
    },
    featureIds: features ? features.map((item: any) => Number(item.id)) : [],
    coverImageId: cover?.id,
    typeId: type?.id,
    deposit: deposit ? Number(deposit) : deposit,
    monthlyRent: monthlyRent ? Number(monthlyRent) : monthlyRent,
    agentBankAccountId: agentBankAccount?.id,
  };
};

export const convertPropertyBody = (data?: Property | null) => {
  return {
    description: data?.description,
    typeId: data?.typeId,
    energySystem: data?.energySystem,
    availableFrom: data?.availableFrom,
    deposit: data?.deposit,
    monthlyRent: data?.monthlyRent,
    landArea: data?.landArea,
    furnishing: data?.furnishing || 'not_furnished',
    buildType: data?.buildType,
    options: data?.options,
    streetView: data?.streetView,
    hasRemoteView: data?.hasRemoteView,
    featureIds: data?.featureIds,
    additionalFeatures: data?.additionalFeatures,
    userRole: data?.userRole || 'landlord',
    landlordEmail: data?.landlordEmail || null,
    address: {
      address_1: data?.address?.address_1,
      address_2: data?.address?.address_2,
      cityId: data?.address?.city?.id,
      latitude: data?.address?.latitude,
      longitude: data?.address?.longitude,
      postcode: data?.address?.postcode,
    },
    files: Boolean(data?.id)
      ? []
      : data?.files?.map((item) => {
          return { fileId: item.fileId, type: item.type };
        }),
    coverImageId:
      data?.coverImageId ||
      data?.files?.filter((item) => item.type == 'image')[0]?.fileId,
    tours: Boolean(data?.id) ? [] : data?.tours,
    bedNumber: data?.bedNumber || 1,
    bathroomNumber: data?.bathroomNumber || 1,
    receptionNumber: data?.receptionNumber || 1,
    tenancyDaysStart: data?.tenancyDaysStart || 1,
    tenancyDaysEnd: data?.tenancyDaysEnd || 12,
    maxAllowedTenants: data?.maxAllowedTenants || 1,
    agentCommissionAmount: Number(data?.agentCommissionAmount),
    agentBankAccountId:
      Number(data?.agentBankAccountId) > 0
        ? Number(data?.agentBankAccountId)
        : null,
  };
};

const Provider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { query, pathname } = useRouter();
  const [center, setCenter] = useState<Position | null>(null);
  const dispatch = useAppDispatch();
  const property = useAppSelector(selectAddProperty);
  const [isConfirmBtn, setIsConfirmBtn] = useState<boolean>(false);
  const { data } = property;
  const [propertyTypesObjectValue, setPropertyTypesObjectValue] = useState<
    Record<number, string>
  >({});
  const [propertyFeaturesObjectValue, setPropertyFeaturesObjectValue] =
    useState<Record<number, string>>({});
  const [propertyLettingTypeObjectValue, setPropertyLettingTypeObjectValue] =
    useState<Record<number, string>>({});
  const [zoomMap, setZoomMap] = useState<number>(15);
  const { data: baseData } = useBaseData();
  const [lastStep, setLastStep] = useState<number>(0);

  const galleryFileCount =
    data?.files?.filter(
      (item) => item.type === 'image' || item.type === 'video'
    ).length ?? 0;

  useEffect(() => {
    if (data) {
      if (!Boolean(data?.typeId)) {
        setLastStep(1);
      } else if (!Boolean(data?.address?.postcode)) {
        setLastStep(2);
      } else if (
        data?.streetView?.StreetView_Heading == '0' &&
        data?.streetView?.StreetView_Zoom == '0' &&
        data?.streetView?.StreetView_Pitch == '0'
      ) {
        setLastStep(4);
      } else if (
        isEmpty(data?.description) ||
        data?.landArea == 0 ||
        data?.energySystem?.length == 0 ||
        !Boolean(data?.epcFile)
      ) {
        setLastStep(5);
      } else if (
        isEmpty(data.files) &&
        isEmpty(data.tours) &&
        isEmpty(data.featureIds) &&
        isEmpty(data.additionalFeatures) &&
        Number(query.step) !== 7
      ) {
        setLastStep(6);
      } else if (galleryFileCount < 3) setLastStep(7);
      else if (
        !Boolean(data?.monthlyRent) ||
        !Boolean(data?.availableFrom) ||
        !Boolean(data?.buildType)
      )
        setLastStep(8);
    }
  }, [data]);

  useEffect(() => {
    if (baseData) {
      let obj1: Record<number, string> = {};
      baseData.propertyTypes.map((item) => {
        obj1[item.id] = item.title;
        item.children.map((child) => {
          obj1[child.id] = child.title;
        });
      });
      setPropertyTypesObjectValue(obj1);

      ///convert Features data to object
      let obj2: Record<number, string> = {};
      baseData.propertyFeatures.map((item) => {
        if (item.type === 'feature') obj2[item.id] = item.title;
      });
      setPropertyFeaturesObjectValue(obj2);

      ///convert Features data to object
      let obj3: Record<number, string> = {};
      baseData.propertyFeatures.map((item) => {
        if (item.type === 'allowed_tenant_type') obj3[item.id] = item.title;
      });
      setPropertyLettingTypeObjectValue(obj3);
    }
  }, [baseData]);

  const handleZoomMap = (value: number) => {
    setZoomMap(value);
  };

  useEffect(() => {
    if (center) {
      const obj = data?.address;
      const objectValue = {
        ...data,
        ['address']: { ...obj, ...center },
      };
      dispatch(handleData(objectValue));
    }
  }, [center]);

  const next = (propertyId?: number) => {
    if (Boolean(query.id) || Boolean(propertyId)) {
      router.push(
        pathname +
          `?step=${Number(query.step) + 1}&id=${
            Number(query.id) || Number(propertyId)
          }`
      );
    } else router.push(pathname + `?step=${Number(query.step) + 1}`);
  };
  const back = (propertyId?: number) => {
    if (Number(query.step) == 1) {
      router.push(pathname);
    } else {
      if (query.id || Boolean(propertyId)) {
        router.push(
          pathname +
            `?step=${Number(query.step) - 1}&id=${
              Number(query.id) || Number(propertyId)
            }`
        );
      } else {
        router.push(pathname + `?step=${Number(query.step) - 1}`);
      }
    }
  };

  const handleRoute = (step: number) => {
    const propertyId = query.id;
    if (propertyId) {
      router.push(
        pathname + `?step=${step}&id=${Number(query.id) || Number(propertyId)}`
      );
    } else {
      router.push(pathname + `?step=${step}`);
    }
  };

  const onChangePosition = (value: Position) => {
    setCenter(value);
  };

  return (
    <Context.Provider
      value={{
        next,
        back,
        onChangePosition,
        zoomMap,
        handleZoomMap,
        propertyTypesObjectValue,
        propertyFeaturesObjectValue,
        propertyLettingTypeObjectValue,
        isConfirmBtn,
        setIsConfirmBtn,
        lastStep,
        handleRoute,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default Provider;
