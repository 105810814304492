/* eslint-disable @typescript-eslint/no-unused-vars */
import { toast } from 'react-toastify';
import { font } from './constants';

export const error = (message: string | undefined) => {
  toast.error(message, {
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    // autoClose:false,
    icon: false,
    style: {
      fontFamily: font,
      fontSize: '14px',
      borderRadius: '12px',
      border: '1px solid #EB5757',
      boxShadow: 'none',
      color: '#4D4D4D',
    },
  });
};

export const success = (message: string | undefined) => {
  toast.success(message, {
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    icon: false,
    style: {
      fontFamily: font,
      fontSize: '14px',
      borderRadius: '12px',
      border: '1px solid #6FCF97',
      boxShadow: 'none',
      color: '#4D4D4D',
    },
  });
};

export const warning = (message: string | undefined) => {
  toast.warning(message, {
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    icon: false,
    style: {
      fontFamily: font,
      fontSize: '14px',
      borderRadius: '12px',
      border: '1px solid #F2C94C',
      boxShadow: 'none',
      color: '#4D4D4D',
    },
  });
};
