import { createContext, useContext, useState, ReactNode } from 'react';

interface IContext {
  data: Search;
  onChangeData: (
    name: Name,
    value: string | number[] | string[] | null
  ) => void;
  handleData: (value: Search) => void;
  resetData: () => void;
  selected: string;
  handleSelected: (value: string) => void;
  locations: Location[] | null;
  handleLocations: (value: Location[] | null) => void;
  result: Data<Property[]> | null;
  handleResult: (data: Data<Property[]>) => void;
}

const Context = createContext<IContext | null>(null);

export function useSearchContext() {
  return useContext(Context);
}

const Provider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<Search>({
    distance: '2',
    ordering: 'newest',
  });
  const [selected, setSelected] = useState<string>('');
  const [locations, setLocations] = useState<Location[] | null>([]);
  const [result, setResult] = useState<Data<Property[]> | null>(null);

  const resetData = () => {
    setData({ distance: '2' });
    setResult(null)
  };

  const onChangeData = (
    name: Name,
    value: string | number[] | string[] | null
  ) => {
    const objectvalue = {
      ...data,
      [name]: value,
    };
    setData(objectvalue);
  };

  const handleData = (value: Search) => {
    setData(value);
  };

  const handleSelected = (value: string) => {
    setSelected((old) => (old === value ? '' : value));
  };

  const handleLocations = (value: Location[] | null) => {
    setLocations(value);
  };

  const handleResult = (value: Data<Property[]>) => {
    setResult(value);
  };

  return (
    <Context.Provider
      value={{
        onChangeData,
        resetData,
        data,
        handleData,
        handleSelected,
        selected,
        locations,
        handleLocations,
        result,
        handleResult,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default Provider;

export enum Name {
  typeIds = 'typeIds',
  furnishing = 'furnishing',
  depositMin = 'depositMin',
  depositMax = 'depositMax',
  monthlyRentMin = 'monthlyRentMin',
  monthlyRentMax = 'monthlyRentMax',
  bedNumberMin = 'bedNumberMin',
  bedNumberMax = 'bedNumberMax',
  addedAt = 'addedAt',
  availableFrom = 'availableFrom',
  latitude = 'latitude',
  longitude = 'longitude',
  distance = 'distance',
  features = 'features',
  area = 'area',
  ordering = 'ordering',
}
