import {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

interface IContext {
  categories: Category[] | null;
  handleCategories: (arg: Category[]) => void;
  category: string;
  handleCategory: (value: string) => void;
  faqs: FAQ[] | null;
  handleFaqs: (arg: FAQ[] | null) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}
const Context = createContext<IContext | null>(null);

export function useHelpContext() {
  return useContext(Context);
}

const Provider = ({ children }: { children: ReactNode }) => {
  const [categories, setCategories] = useState<Category[] | null>(null);
  const [category, setCategory] = useState<string>('');
  const [faqs, setFaqs] = useState<FAQ[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCategories = (arg: Category[]) => {
    setCategories(arg);
  };

  const handleCategory = (arg: string) => {
    setCategory(arg);
  };

  const handleFaqs = (arg: FAQ[] | null) => {
    setFaqs(arg);
  };

  return (
    <Context.Provider
      value={{
        categories,
        handleCategories,
        category,
        handleCategory,
        faqs,
        handleFaqs,
        loading,
        setLoading,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default Provider;
