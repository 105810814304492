/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import { http } from 'common/config/middleware';

const useHttp = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  const sendRequest = useCallback(
    async (
      {
        url,
        method,
        body,
        upload = false,
        token,
        responseType = 'json',
      }: RequestConfig,
      onSuccess: (res: Responsive) => void
    ) => {
      setLoading(true);
      setError(null);
      const abort = new AbortController();
      setAbortController(abort);

      // Set user's token in headers
      let headers: Record<string, string> = {};

      if (token) headers['Authorization'] = `Bearer ${token}`;

      // Set Content-Type of Upload Section
      if (!upload) headers['Content-Type'] = 'application/json';

      try {
        const response = await http({
          url: url,
          method: method || 'GET',
          responseType,
          headers,
          data: body ? (upload ? body : JSON.stringify(body)) : undefined,
          cancelToken: new http.CancelToken((cancel) => {
            abort.signal.addEventListener('abort', () => {
              cancel('Request aborted');
            });
          }),
        });
        const data = response.data;
        onSuccess(data);
      } catch (err: any) {
        // if (
        //   err.name !== 'AbortError' &&
        //   err.message !== 'Unauthorized' &&
        //   err.name != 'TypeError'
        // )
        //   toastError(err.message || 'Something went wrong!');
        setError(err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { sendRequest, error, loading };
};
export default useHttp;
