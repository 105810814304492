import { useQuery, useQueryClient } from 'react-query';
import * as api from '../queries/api';
import { queryKeys } from 'common/utils/constants';



export const useExpensiveProperty = () => {
  const { data } = useQuery<Data<Property[]>>(
    [queryKeys.expensiveProperties],
    () => api.getExpensiveProperties().then((res) => res.data),
    {
      keepPreviousData: true,
      cacheTime: 1000 * 60 * 60 * 4,
    }
  );
  return { data: data?.items };
};

export const useNews = () => {
  const { data } = useQuery<News[]>(
    [queryKeys.news],
    () => api.getNews().then((res) => res.data.items),
    {
      keepPreviousData: true,
      cacheTime: 1000 * 60 * 60 * 24,
    }
  );
  return { data };
};

export const useBaseData = () => {
  const { data } = useQuery<BaseData>(
    [queryKeys?.baseData],
    () => api.getBaseData().then((res) => res.data),
    {
      keepPreviousData: true,
      cacheTime: 1000 * 60 * 60 * 24,
    }
  );
  return { data };
};
