/* eslint-disable import/first */
import React, { ReactNode } from 'react';
import ModalProvider from './modal-context';
import AuthProvider from './auth-context';
import ProfileProvider from './profile-context';
import HelpProvider from './help-context';
import SearchProvider from './search-context';
import ContractProvider from './contract-context';
import PropertyContext2 from './property-context';

const Index = ({ children }: { children: ReactNode }) => {
  return (
    <AuthProvider>
      <ModalProvider>
        <ProfileProvider>
          <HelpProvider>
            <PropertyContext2>
              <ContractProvider>
                <SearchProvider>{children}</SearchProvider>
              </ContractProvider>
            </PropertyContext2>
          </HelpProvider>
        </ProfileProvider>
      </ModalProvider>
    </AuthProvider>
  );
};
export default Index;
