import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { useProfileContext } from 'common/context/profile-context';

type UserType = 'tenant' | 'landlord' | 'agent' | '';
interface IContext {
  data: Contract | null;
  handleData: (value: Contract | null) => void;
  isFetching: boolean;
  handleIsFetching: (value: boolean) => void;
  disabled: boolean;
  userType: UserType;
  setContractText: React.Dispatch<React.SetStateAction<ContractText | null>>;
  contractText: ContractText | null;
  users: Record<string, Profile | undefined>;
}
const Context = createContext<IContext | null>(null);

export function useContractContext() {
  return useContext(Context);
}
const Provider = ({ children }: { children: ReactNode }) => {
  const profileCtx = useProfileContext();
  const [data, setData] = useState<Contract | null>(null);
  const [contractText, setContractText] = useState<ContractText | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [userType, setUserType] = useState<UserType>('');
  const [users, setUsers] = useState<Record<string, Profile | undefined>>({});

  const handleData = (value: Contract | null) => {
    setData(value);
  };

  useEffect(() => {
    let profileId = profileCtx?.data?.id;
    if (profileId && data) {
      if (data?.landlord?.id == profileId) {
        setUserType('landlord');
        setUsers({
          tenant: data?.tenant,
          agent: data?.agent,
        });
      } else if (data?.tenant?.id == profileId) {
        setUserType('tenant');
        setUsers({
          landlord: data?.landlord,
          agent: data?.agent,
        });
      } else if (data?.agent?.id == profileId) {
        setUserType('agent');
        setUsers({
          landlord: data?.landlord,
          tenant: data?.tenant,
        });
      }
    }
  }, [profileCtx?.data, data]);

  useEffect(() => {
    if (data) {
      setIsFetching(false);
    }
    if (
      profileCtx?.data?.id === data?.agent?.id ||
      data?.status !== 'in_progress'
    ) {
      setDisabled(true);
    } else setDisabled(false);
  }, [data, profileCtx]);

  // const handleContractText = (value: ContractText) => {
  //   setContractText(value);
  // };
  const handleIsFetching = (value: boolean) => {
    setIsFetching(value);
  };

  

  return (
    <Context.Provider
      value={{
        users,
        handleData,
        data,
        isFetching,
        handleIsFetching,
        disabled,
        userType,
        setContractText,
        contractText,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default Provider;
