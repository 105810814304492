import { http } from 'common/config/middleware';


export const getExpensiveProperties = () =>
  http
    .get(
      '/properties?distance=2&longitude=-0.273261&latitude=51.50814&area=Acton&ordering=price_high'
    )
    .then((res) => res.data);
export const getNews = () =>
  http
    .get('/posts?categoryType=news&ordering=top-view')
    .then((res) => res.data);
export const getBaseData = () =>
  http.get('/basics/base-data').then((res) => res.data);

// export const postPlanSubscribe = ()=>{
//     axios.post
// }

