import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface InitialState {
  data: Property | null;
  initialData: Property | null;
  step: number;
}

const initialState: InitialState = {
  initialData: null,
  data: null,
  step: 0,
};

export const slice = createSlice({
  name: 'addProperty',
  initialState,
  reducers: {
    handleData: (state, action: PayloadAction<Property | null>) => {
      state.data = action.payload;
    },
    handleInitialDataData: (state, action: PayloadAction<Property | null>) => {
      state.initialData = action.payload;
    },
    onChangeData: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      const { key, value } = action.payload;
      state.data = {
        ...state.data,
        [key]: value,
      };
    },
    handleStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
  },
});

export const { handleData, onChangeData, handleInitialDataData, handleStep } =
  slice.actions;
export const selectAddProperty = (state: RootState) => state.addProperty;
export default slice.reducer;
