import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import inspection from './inspection/slice';
import inspectionUtilities from './inspection/utilities/slice';
import inspectionRoomsReport from './inspection/roomsReport/slice';
import addProperty from './addProperty/slice';
import reportGallery from './reportGallery/slice';

export const store = configureStore({
  reducer: {
    inspection,
    inspectionUtilities,
    inspectionRoomsReport,
    reportGallery,
    addProperty,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
