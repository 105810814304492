import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../index';

interface InitialState {
  rooms: InspectionRoom[] | null;
  roomTitles: string[] | null;
  itemReports: InspectionReport[];
  initialItemReports: InspectionReport[];
}

const initialState: InitialState = {
  rooms: null,
  roomTitles: null,
  itemReports: [],
  initialItemReports: [],
};

export const slice = createSlice({
  name: 'inspection/roomsReport',
  initialState,
  reducers: {
    setRooms: (state, action: PayloadAction<InspectionRoom[]>) => {
      state.rooms = action.payload;
    },
    setRoomDefaultTitles: (state, action: PayloadAction<string[]>) => {
      state.roomTitles = action.payload;
    },
    setItemReports: (state, action: PayloadAction<InspectionReport[]>) => {
      state.itemReports = action.payload;
      state.initialItemReports = action.payload;
    },
    onChangeItemReport: (state, action: PayloadAction<InspectionReport>) => {
      const findIndex = state.itemReports.findIndex(
        (item) => item.itemId === action.payload.itemId
      );
      if (findIndex > -1) {
        state.itemReports[findIndex] = {
          ...state.itemReports[findIndex],
          ...action.payload,
        };
      }
    },

  },
});

export const {
  setRooms,
  setRoomDefaultTitles,
  onChangeItemReport,
  setItemReports,
} = slice.actions;
export const selectRoomsReportInspection = (state: RootState) =>
  state.inspectionRoomsReport;
export default slice.reducer;
