import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import {
  baseUrl,
  AccessTokenName,
  RefreshTokenName,
} from 'common/utils/constants';
import { handleTokenInCookie, isExpiredToken } from 'common/utils/methods';
import { useRouter } from 'next/router';
import useHttp from 'common/hooks/useHttp';
import { getTokenInCookie } from 'common/utils/methods';

interface Tokens {
  [AccessTokenName]: string;
  [RefreshTokenName]: string;
}
interface IContext {
  isLogin: boolean | null;
  toggleLogin: () => void;
  handleLogin: (value: boolean) => void;
  setIsLogin: Dispatch<SetStateAction<boolean | null>>;
  login: (accessToken: string | null, refreshToken: string | null) => void;
  logOut: () => void;
  tokens: Tokens;
}
const Context = createContext<IContext | null>(null);

export function useAuthContext() {
  return useContext(Context);
}
const Provider = ({ children }: { children: ReactNode }) => {
  // const [isLogin, setIsLogin] = useLocalStorage('isLogin', false);
  const [isLogin, setIsLogin] = useState<boolean | null>(null);
  const { loading, sendRequest, error } = useHttp();
  const { query, isReady } = useRouter();
  const router = useRouter();
  const [tokens, setTokens] = useState<Tokens>({
    [AccessTokenName]: '',
    [RefreshTokenName]: '',
  });

  useEffect(() => {
    // if token was deleted update isLogin state
    if (isReady) {
      if (Boolean(tokens[RefreshTokenName])) {
        if (isExpiredToken(tokens[RefreshTokenName])) {
          setIsLogin(false);
        } else setIsLogin(true);
      }
    }
  }, [tokens[RefreshTokenName], isReady]);

  useEffect(() => {
    fetchTokens();
  }, []);

  const fetchTokens = async () => {
    const tokens = await getTokenInCookie();
    setTokens(tokens);
  };

  useEffect(() => {
    // If you encounter the "app_to_web_token" in the root URL, it indicates that the app will redirect to the web page for accessing a text editor.
    if (isReady && Boolean(query.app_to_web_token)) {
      sendRequest(
        {
          url: '/auth/access-token-from-app-to-web-token',
          method: 'POST',
          body: { token: query.app_to_web_token?.toString().trim() },
        },
        onSuccessAppToken
      );
    }
  }, [query, isReady]);

  const onSuccessAppToken = (res: Responsive) => {
    login(res.data.accessToken, res.data.refreshToken);
    router.push(
      baseUrl + router.asPath.split('?app_to_web_token=')[0] + '?from=mobile'
    );
  };

  const toggleLogin = () => {
    setIsLogin((prev: boolean | null) => !prev);
  };
  const handleLogin = (value: boolean) => {
    setIsLogin(value);
  };

  const login = (accessToken: string | null, refreshToken: string | null) => {
    if (accessToken && refreshToken) {
      // Set token in cookie
      handleTokenInCookie(accessToken, AccessTokenName, 'POST');
      handleTokenInCookie(refreshToken, RefreshTokenName, 'POST');
      setTokens({
        [AccessTokenName]: accessToken,
        [RefreshTokenName]: refreshToken,
      });
      setIsLogin(true);
    }
  };

  const logOut = () => {
    handleTokenInCookie('', AccessTokenName, 'DELETE');
    handleTokenInCookie('', RefreshTokenName, 'DELETE');
    setTokens({
      [AccessTokenName]: '',
      [RefreshTokenName]: '',
    });
    setIsLogin(false);
  };

  return (
    <Context.Provider
      value={{
        isLogin,
        toggleLogin,
        handleLogin,
        setIsLogin,
        login,
        logOut,
        tokens,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default Provider;
